import { useTranslation } from "react-i18next";
import background from "../../resources/img/other/background.jpg";
import doc_1 from "../../resources/docs/politics/Академиялық адалдық.pdf";
import doc_2 from "../../resources/docs/politics/Оқушыны қабылдау.pdf";
import doc_3 from "../../resources/docs/politics/Тәртіптік саясат.pdf";
import doc_4 from "../../resources/docs/politics/Тіл саясаты.pdf";
import doc_5 from "../../resources/docs/politics/Нормативті құжаттар.pdf";

const PoliticsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="saysat">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={background}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-20 pb-20">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  <span className="color__prime">{t("politics.title_1")}</span>
                </h1>
                <h2 className="subtitle is-5 light-text">
                  {t("politics.description_1")}
                </h2>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium download">
        <div className="container">
          <div className="section-title-wrapper">
            <h2 className="title section-title has-text-centered dark-text">
              {t("politics.title_2")}
            </h2>
          </div>
          <div className="content-wrapper">
            <div className="download__content">
              <a
                href={doc_1}
                target="_blank"
                rel="noreferrer"
                className="download__item"
              >
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-File-Bookmark"></i>
                </div>
                <div className="download__name">Академиялық адалдық</div>
              </a>
              <a
                href={doc_2}
                target="_blank"
                rel="noreferrer"
                className="download__item"
              >
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-File-Bookmark"></i>
                </div>
                <div className="download__name">Оқушыны қабылдау</div>
              </a>
              <a
                href={doc_3}
                target="_blank"
                rel="noreferrer"
                className="download__item"
              >
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-File-Bookmark"></i>
                </div>
                <div className="download__name">Тәртіптік саясат</div>
              </a>
              <a
                href={doc_4}
                target="_blank"
                rel="noreferrer"
                className="download__item"
              >
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-File-Bookmark"></i>
                </div>
                <div className="download__name">Тіл саясаты</div>
              </a>
              <a
                href={doc_5}
                target="_blank"
                rel="noreferrer"
                className="download__item"
              >
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-File-Bookmark"></i>
                </div>
                <div className="download__name">Нормативті құжаттар</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliticsPage;
